import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialState = {
    visitorTypeErrorMessage: string;
    unAuthorized: boolean
    notFound: boolean,
    currentScreen:string,
    emailErrorMessage: string,
    passwordErrorMessage: string,
    newPasswordErrorMessage: string,
    confirmPasswordErrorMessage: string,
    editPasswordErrorMessage : string,

    //project
    nameErrorMessage: string,
    projectErrorMessage: string,
    depMeetErrorMessage: string,
    areaOfCurrentResidentErrorMessage: string,
    mobileErrorMessage: string,
    alternateMobileErrorMessage: string,
    preferredUnitsErrorMessage: string,
    preferredBudgetRangeErrorMessage: string,
    howDidYouKnowAboutUsErrorMessage: string,
    socialMediaErrorMessage : string,
    partnerNameErrorMessage : string,
    empNameErrorMessage: string,
    empIdErrorMessage:string,
    contactErrorMessage:string,
    refProjectErrorMessage:string,
    labelErrorMessage:string,
    descriptionErrorMessage: string,
    purposeOfVisitErrorMessage : string,
    unitNoErrorMessage: string,

    leadIdErrorMessage: string,
    statusErrorMessage: string,
    flsErrorMessage: string,
    flsTlErrorMessage: string,
    remarksErrorMessage:string,

    //user
    firstNameErrorMessage: string,
    lastNameErrorMessage: string,
    userMobileErrorMessage: string,
    userRoleErrorMessage: string,
    tlErrorMessage: string,
    svcErrorMessage: string,
    salesRoleErrorMessage: string,

    //master
    roleNameErrorMessage: string,
    departmentNameErrorMessage: string,
    minAgeErrorMessage: string,
    maxAgeErrorMessage: string,
    occupationErrorMessage: string,
    minbudgetErrorMessage: string,
    maxbudgetErrorMessage: string,
    minPreferredSizeErrorMessage: string,
    maxPreferredSizeErrorMessage: string,
    preferredUnitErrorMessage: string,
    projectNameErrorMessage: string,
    purposeOfPurchaseErrorMessage: string,
    knowAboutUsErrorMessage: string,
    statusNameErrorMessage: string,

    companyNameErrorMessage: string,
    visitTypeErrorMessage: string,
    excelFileErrorMessage:string,
    employeeMobileErrorMessage:string
}

const initialState: initialState = {
    unAuthorized: false,
    notFound: false,
    currentScreen:'',
    emailErrorMessage: '',
    passwordErrorMessage: '',
    newPasswordErrorMessage:'',
    confirmPasswordErrorMessage: '',
    editPasswordErrorMessage:'',

    visitorTypeErrorMessage:'',
    nameErrorMessage: '',
    projectErrorMessage: '',
    depMeetErrorMessage: '',
    areaOfCurrentResidentErrorMessage: '',
    mobileErrorMessage: '',
    alternateMobileErrorMessage: '',
    preferredUnitsErrorMessage: '',
    preferredBudgetRangeErrorMessage: '',
    howDidYouKnowAboutUsErrorMessage: '',
    socialMediaErrorMessage: '',
    partnerNameErrorMessage: '',
    empNameErrorMessage: '',
    empIdErrorMessage:'',
    labelErrorMessage:'',
    descriptionErrorMessage:'',
    purposeOfVisitErrorMessage: '',
    contactErrorMessage: '',
    refProjectErrorMessage: '',
    unitNoErrorMessage: '',

    leadIdErrorMessage: '',
    statusErrorMessage: '',
    flsErrorMessage: '',
    flsTlErrorMessage: '',
    remarksErrorMessage:'',

    //user
    firstNameErrorMessage: '',
    lastNameErrorMessage: '',
    userMobileErrorMessage: '',
    userRoleErrorMessage: '',
    tlErrorMessage: '',
    svcErrorMessage: '',
    salesRoleErrorMessage: '',

    //master
    roleNameErrorMessage: '',
    departmentNameErrorMessage: '',
    minAgeErrorMessage: '',
    maxAgeErrorMessage: '',
    occupationErrorMessage: '',
    minbudgetErrorMessage: '',
    maxbudgetErrorMessage: '',
    minPreferredSizeErrorMessage: '',
    maxPreferredSizeErrorMessage: '',

    preferredUnitErrorMessage: '',
    projectNameErrorMessage: '',
    purposeOfPurchaseErrorMessage: '',

    knowAboutUsErrorMessage: '',
    statusNameErrorMessage: '',
    companyNameErrorMessage: '',
    visitTypeErrorMessage:'',
    excelFileErrorMessage:'',
    employeeMobileErrorMessage:''
}

const ErrorSlice = createSlice({
    name: 'errorMessage',
    initialState,
    reducers: {
        setUnAuthorized: (state: initialState, action: PayloadAction<boolean>) => {
            state.unAuthorized = action.payload;
        },
        setNotfound: (state: initialState, action: PayloadAction<boolean>) => {
            state.notFound = action.payload;
        },
        setCurrentScreen: (state: initialState, action: PayloadAction<string>) => {
            state.currentScreen = action.payload;
        },
        setEmailErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.emailErrorMessage = action.payload;
        },
        setPasswordErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.passwordErrorMessage = action.payload;
        },
        setNewPasswordErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.newPasswordErrorMessage = action.payload;
        },
        setConfirmPasswordErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.confirmPasswordErrorMessage = action.payload;
        },

        setEditPasswordErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.editPasswordErrorMessage = action.payload;
        },
        setVisitorTypeErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.visitorTypeErrorMessage = action.payload;
        },
        //Aquicistion
        setProjectErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.projectErrorMessage = action.payload;
        },
        setDepMeetErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.depMeetErrorMessage = action.payload;
        },
        setNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.nameErrorMessage = action.payload;
        },
        setAreaOfCurrentResidentErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.areaOfCurrentResidentErrorMessage = action.payload;
        },
        setMobileErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.mobileErrorMessage = action.payload;
        },
        setAlternateMobileErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.alternateMobileErrorMessage = action.payload;
        },
        setPreferredUnitsErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.preferredUnitsErrorMessage = action.payload;
        },
        setPreferredBudgetErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.preferredBudgetRangeErrorMessage = action.payload;
        },
        setHowDidYouKnowAboutUsErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.howDidYouKnowAboutUsErrorMessage = action.payload;
        },
        setSocialMediaErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.socialMediaErrorMessage = action.payload;
        }, 
        setPartnerNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.partnerNameErrorMessage = action.payload;
        },               
        setEmpNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.empNameErrorMessage = action.payload;
        },
        setEmpIdErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.empIdErrorMessage = action.payload;
        },
        setLabelErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.labelErrorMessage = action.payload;
        },
        setDescriptionErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.descriptionErrorMessage = action.payload;
        },
        setPurposeOfVisitErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.purposeOfVisitErrorMessage = action.payload;
        },

        setContactErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.contactErrorMessage = action.payload;
        },        
        setRefProjectErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.refProjectErrorMessage = action.payload;
        },
        setUnitNoErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.unitNoErrorMessage = action.payload;
        }, 
        setCompanyNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.companyNameErrorMessage = action.payload;
        },  
        setVisitTypeErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.visitTypeErrorMessage = action.payload;
        },


        //******************* */
        setLeadIdErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.leadIdErrorMessage = action.payload;
        },
        setStatusErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.statusErrorMessage = action.payload;
        },
        setFlsErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.flsErrorMessage = action.payload;
        },
        setFlsTlErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.flsTlErrorMessage = action.payload;
        },
        setRemarks: (state: initialState, action: PayloadAction<string>) => {
            state.remarksErrorMessage = action.payload;
        },
        //user
        setFirstNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.firstNameErrorMessage = action.payload;
        },
        setLastNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.lastNameErrorMessage = action.payload;
        },
        setUserMobileErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.userMobileErrorMessage = action.payload;
        },
        setUserRoleErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.userRoleErrorMessage = action.payload;
        },

        //master
        setRoleNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.roleNameErrorMessage = action.payload;
        },
        setDepartmentNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.departmentNameErrorMessage = action.payload;
        },
        setMinAgeErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.minAgeErrorMessage = action.payload;
        },
        setMaxAgeErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.maxAgeErrorMessage = action.payload;
        },
        setOccupationErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.occupationErrorMessage = action.payload;
        },

        setMinBudgetErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.minbudgetErrorMessage = action.payload;
        },
        setMaxBudgetErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.maxbudgetErrorMessage = action.payload;
        },
        setMinPreferredSizeErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.minPreferredSizeErrorMessage = action.payload;
        },
        setMaxPreferredSizeErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.maxPreferredSizeErrorMessage = action.payload;
        },
        setPreferredUnitErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.preferredUnitErrorMessage = action.payload;
        },
        setProjectNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.projectNameErrorMessage = action.payload;
        },

        setPurposeOfPurchaseErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.purposeOfPurchaseErrorMessage = action.payload;
        },
        setknowAboutUsErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.knowAboutUsErrorMessage = action.payload;
        },
        setStatusNameErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.statusNameErrorMessage = action.payload;
        },


        setTlErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.tlErrorMessage = action.payload;
        },
        setSvcErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.svcErrorMessage = action.payload;
        },
        setSalesRoleErrorMessage: (state: initialState, action: PayloadAction<string>) => {
            state.salesRoleErrorMessage = action.payload;
        },
        setExcelFileErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.excelFileErrorMessage = action.payload;
        },
        setEmployeeMobileErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.employeeMobileErrorMessage = action.payload;
        },

    }
})

export default ErrorSlice.reducer

export const {
    setUnAuthorized,
    setNotfound,
    setCurrentScreen,
    setEmailErrorMessage,
    setPasswordErrorMessage,
    setNewPasswordErrorMessage,
    setConfirmPasswordErrorMessage,
    setEditPasswordErrorMessage,

    setDepMeetErrorMessage,
    setProjectErrorMessage,
    setVisitorTypeErrorMessage,
    setNameErrorMessage,
    setAreaOfCurrentResidentErrorMessage,
    setMobileErrorMessage,
    setAlternateMobileErrorMessage,
    setPreferredUnitsErrorMessage,
    setPreferredBudgetErrorMessage,
    setHowDidYouKnowAboutUsErrorMessage,
    setSocialMediaErrorMessage,
    setPartnerNameErrorMessage,
    setEmpNameErrorMessage,
    setEmpIdErrorMessage,
    setContactErrorMessage,
    setRefProjectErrorMessage,
    setLabelErrorMessage,
    setDescriptionErrorMessage,
    setPurposeOfVisitErrorMessage,
    setUnitNoErrorMessage,

    //AquisitionEdit
    setFlsErrorMessage,
    setLeadIdErrorMessage,
    setFlsTlErrorMessage,
    setStatusErrorMessage,
    setRemarks,
    //user
    setUserMobileErrorMessage,
    setUserRoleErrorMessage,
    setFirstNameErrorMessage,
    setLastNameErrorMessage,
    setSvcErrorMessage,
    setTlErrorMessage,
    //master
    setDepartmentNameErrorMessage,
    setRoleNameErrorMessage,
    setMinAgeErrorMessage,
    setMaxAgeErrorMessage,
    setMinBudgetErrorMessage,
    setMaxBudgetErrorMessage,
    setOccupationErrorMessage,
    setMinPreferredSizeErrorMessage,
    setMaxPreferredSizeErrorMessage,
    setPreferredUnitErrorMessage,
    setProjectNameErrorMessage,
    setPurposeOfPurchaseErrorMessage,
    setStatusNameErrorMessage,
    setknowAboutUsErrorMessage,
    setSalesRoleErrorMessage,
    setCompanyNameErrorMessage,
    setVisitTypeErrorMessage,
    setExcelFileErrorMessage,
    setEmployeeMobileErrorMessage
} = ErrorSlice.actions;
