import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/index';
import { urlConstants } from '../../../constants/urlConstants';

export const EmployeeApi = createApi({
    reducerPath: 'EmployeeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getEmployeeList: builder.query<any, any>({
            query: (searchParams: any) => ({
                url: urlConstants.EMPLOYEE_LIST,
                method: 'GET',
                params: {
                    page: searchParams?.currentPage,
                    searchString: searchParams?.searchString
                }
            }),
        }),
        deleteEmployee: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.EMPLOYEE}/${id}`,
                method: 'DELETE'
            }),
    }),
    getSingleEmployee: builder.query<any, any>({
        query: (id: any) => ({
            url: `${urlConstants.EMPLOYEE}/${id}`,
            method: 'GET'
        }),
    }),
    editEmployee: builder.mutation<any, any>({
        query: ({ employeeData, id }: any) => ({
            url: `${urlConstants.EMPLOYEE}/${id}`,
            method: 'PUT',
            body: employeeData
        }),
    }),
    addEmployee: builder.mutation<any, any>({
        query: (employeeData: any) => ({
            url: urlConstants.ADD_EMPLOYEE,
            method: 'POST',
            body: employeeData
        })
    }),

})
});

export const {
    useGetEmployeeListQuery,
    useDeleteEmployeeMutation,
    useGetSingleEmployeeQuery,
    useEditEmployeeMutation,
    useAddEmployeeMutation
    
} = EmployeeApi;
