import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/index';
import { urlConstants } from '../../../constants/urlConstants';

export const AquisitionApi = createApi({
    reducerPath: 'AquisitionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`);
            return headers;
        },
    }),
    tagTypes: ['aquisition', 'aquisition-dropdown'],
    endpoints: (builder) => ({
        createAcquisition: builder.mutation<any, any>({
            query: (acquisitionData) => ({
                url: urlConstants.AQUISITION,
                method: 'POST',
                body: acquisitionData,
            }),
            invalidatesTags: ['aquisition'],
        }),
        getAquisitionDropdownsDetails: builder.query<any, void>({
            query: () => ({
                url: urlConstants.AQUISITION_DROPDOWN,
                method: 'GET',
            }),
            providesTags: ['aquisition'],
        }),
        getUserSalesDropdownsDetails: builder.query<any, void>({
            query: () => ({
                url: urlConstants.SALES_DROPDOWN,
                method: 'GET',
            }),
            providesTags: ['aquisition-dropdown'],
        }),
        getAcquisitionList: builder.query<any, any>({
            query: (searchParams) => ({
                url: urlConstants.AQUISITION_LIST,
                method: 'GET',
                params: {
                    // page: searchParams?.currentPage,
                    searchString: searchParams?.searchString,
                    fromDate: searchParams?.fromDate,
                    toDate: searchParams?.toDate
                },
            }),
            providesTags: ['aquisition'],
        }),
        editAcquisition: builder.mutation<any, any>({
            query: ({ acquisitionData, id }) => ({
                url: `${urlConstants.CUSTOMER}/${id}`,
                method: 'PUT',
                body: acquisitionData,
            }),
            invalidatesTags: ['aquisition'],
        }),
        editAcquisitionSF: builder.mutation<any, any>({
            query: ({ acquisitionData, id }) => ({
                url: `${urlConstants.UPDATE_AQUISITOIN_SF}/${id}`,
                method: 'PUT',
                body: acquisitionData,
            }),
            invalidatesTags: ['aquisition'],
        }),
        getSingleCustomer: builder.query<any, { id: string }>({
            query: (id) => ({
                url: `${urlConstants.CUSTOMER}/${id}`,
                method: 'GET',
            }),
            providesTags: ['aquisition'],
        }),
        deleteAcquisition: builder.mutation<any, any>({
            query: (id) => ({
                url: `${urlConstants.CUSTOMER}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['aquisition'],
        }),
        assignFieldDropdownList: builder.query<any, any>({
            query: () => ({
                url: urlConstants.FIELD_EMPLOYEE_DROPDOWN,
                method: 'GET',
            }),
            providesTags: ['aquisition-dropdown'],
        }),
        sendOtp: builder.mutation<any, any>({
            query: (phoneNumber) => ({
                url: urlConstants.SENDOTP,
                method: 'POST',
                body: { phoneNumber },
            }),
        }),
        validateOtp: builder.mutation<any, any>({
            query: ({ phoneNumber, otp }) => ({
                url: urlConstants.VALIDATEOTP,
                method: 'POST',
                body: { phoneNumber, otp },
            }),
        }),
        getCustomerDuplicates: builder.query<any, any>({
            query: ({ id }) => ({
                url: `${urlConstants.DUPLICATES}/${id}`,
                method: 'GET',
            }),
        }),
        getPersonToMeetDropdown: builder.query<any, void>({
            query: () => ({
                url: urlConstants.PERSON_TO_MEET_DROPDOWN,
                method: 'GET',
            }),
            providesTags: ['aquisition-dropdown'],
        }),
    }),
});

export const {
    useCreateAcquisitionMutation,
    useGetAquisitionDropdownsDetailsQuery,
    useGetUserSalesDropdownsDetailsQuery,
    useEditAcquisitionSFMutation,
    useGetAcquisitionListQuery,
    useEditAcquisitionMutation,
    useGetSingleCustomerQuery,
    useDeleteAcquisitionMutation,
    useAssignFieldDropdownListQuery,
    useSendOtpMutation,
    useValidateOtpMutation,
    useGetCustomerDuplicatesQuery,
    useGetPersonToMeetDropdownQuery,
} = AquisitionApi;
