export const urlConstants = {
    LOGIN: '/user/login',
    CUSTOMER: '/customer',
    DUPLICATES:'/customer/duplicates',
    SENDOTP:'/customer/send-otp',
    VALIDATEOTP:'/customer/verify-otp',
    DASHBOARD:'/dashboard/visitor-counts',
    AQUISITION: '/customer/add',
    AQUISITION_LIST: '/customer/list',
    AQUISITION_DROPDOWN: '/dropdowns/get-dropdown-data',
    PERSON_TO_MEET_DROPDOWN: '/employee/dropdown',

    SALES_DROPDOWN: '/dropdowns/get-sales-dropdown-data',
    UPDATE_AQUISITOIN_SF:'/customer/assignSalesTeam',
    CREATE_USER:'/user/register',
   
    USER_DROPDOWNS:'/user-creation/dropdown-data',
    USERS_LIST:'/user/list',
    USERS_PASSWORD_CHANGE: '/changePassword',
    USER:'/user',
    EMPLOYEE: '/employee',
    PROFILE:'/user-profile',

    DEPARTMENT:'/department',
    DEPARTMENT_LIST:'/department/list',
    DEPARTMENT_CREATE:'/department/add',

    ROLE:'/role',
    ROLE_LIST:'/role/list',
    ROLE_CREATE:'/role/add',

    AGE:'/ageRange',
    AGE_LIST:'/ageRange/list',
    AGE_CREATE:'/ageRange/add',

    OCCUPATION:'/occupation',
    OCCUPATION_LIST:'/occupation/list',
    OCCUPATION_CREATE:'/occupation/add',

    SOURCE_OF_INFO:'/source',
    SOURCE_OF_INFO_LIST:'/source/list',
    SOURCE_OF_INFO_CREATE:'/source/add',

    BUDGET_RANGE:'/budgetRange',
    BUDGET_RANGE_LIST:'/budgetRange/list',
    BUDGET_RANGE_CREATE:'/budgetRange/add',

    PREFERRED_SIZE:'/unitSize',
    PREFERRED_SIZE_LIST:'/unitSize/list',
    PREFERRED_SIZE_CREATE:'/unitSize/add',

    PREFERRED_UNIT:'/unitType',
    PREFERRED_UNIT_LIST:'/unitType/list',
    PREFERRED_UNIT_CREATE:'/unitType/add',

    PROJECT:'/project',
    PROJECT_LIST:'/project/list',
    PROJECT_CREATE:'/project/add',
    SF_PROJECTS:'/salesforce-projects',

    PURPOSE_OF_PURCHASE:'/purpose',
    PURPOSE_OF_PURCHASE_LIST:'/purpose/list',
    PURPOSE_OF_PURCHASE_CREATE:'/purpose/add',

    STATUS:'/budgetRange',
    STATUS_LIST:'/budgetRange/list',
    STATUS_CREATE:'/budgetRange/add',

    SCREENS:'/screens/list',
    
    // FIELD_EMPLOYEE_DROPDOWN:'fieldEmployee/dropdown-data',
    FIELD_EMPLOYEE_DROPDOWN:'fieldEmployee/list',

    FIELD_EMPLOYEE_CREATE:'/user/field-employee',
    FIELD_EMPLOYEE_LIST:'/user/get-field-employees',
    FIELD_EMPLOYEE:'/user/field-employee',

    USER_PERMISSSION:'/user-permissions',
    REPORT:'/report',
    DOWNLOAD_REPORT:'/report/excel',

    ADD_EMPLOYEE:'/employee/add',
    EMPLOYEE_LIST: '/employee/list',
    BULK_IMPORT: '/employee/bulk-import',
    SAMPLE_FILE: '/employee-create/sample-file'
}