import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import './scss/common.scss';
import './App.css';
import { Routes, Route } from "react-router-dom"
import { Suspense } from 'react';
import Loading from './pages/miscellanious/loading'
import { PATH } from './constants/path';
import 'react-responsive-pagination/themes/classic.css';
import { useAppSelector } from './base/hooks/hooks';

const Header = React.lazy(() => import('./layouts/customerLayout/index'))
const AccessDenied = React.lazy(() => import('./pages/miscellanious/accessDenied/accessDenied'))
const NotFound = React.lazy(() => import('./pages/miscellanious/notFound'))
const Login = React.lazy(() => import('./pages/login/login'))
const Home = React.lazy(() => import('./pages/home/index'))
const AcquisitionForm = React.lazy(() => import('./pages/acquisition/acquisitionForm/acquisitionForm'))
const AcquisitionFormMain = React.lazy(() => import('./pages/acquisition/acquisitionFormMain/acquisitionFormMain'))
const AcquisitionList = React.lazy(() => import('./pages/acquisition/acquisitionList/acquisitionList'))
const AddUser = React.lazy(() => import('./pages/users/addUser/addUser'))
const UserCreationForm = React.lazy(() => import('./pages/users/usersList/usersList'))
const DepartmentAdd = React.lazy(() => import('./pages/department/departmentAdd/departmentAdd'))
const DepartmentList = React.lazy(() => import('./pages/department/departmentList/departmentList'))
const RoleAdd = React.lazy(() => import('./pages/role/createRole/roleAdd'))
const RoleList = React.lazy(() => import('./pages/role/rolesList/roleList'))
const ReportList = React.lazy(() => import('./pages/reportList/reportList'))
const CustomAlert = React.lazy(() => import('./pages/common/customAlert/customAlert'))
const AcquisitionFormMainSuccess = React.lazy(() => import('./pages/acquisition/acquisitionFormMainSuccess/acquisitionFormMainSuccess'))
const Employee = React.lazy(() => import('./pages/employee/employeeList/employeeList'))
const EmployeeAdd = React.lazy(() => import('./pages/employee/employeeAdd/employeeAdd'))
const UploadEmployee = React.lazy(() => import('./pages/users/uploadEmployee/uploadEmployee'))


function App() {
  const { messageNotify } = useAppSelector((state) => state.alertReducer)
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        {messageNotify ? <CustomAlert /> : null}
        <Routes>
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path={PATH.NOT_FOUND} element={<NotFound />} />
          <Route path={PATH.VISITOR_FORM_MAIN} element={<AcquisitionFormMain />} />
          <Route path={PATH.VISITOR_FORM_MAIN_SUCCESS} element={<AcquisitionFormMainSuccess />} />
          <Route element={<Header />}>
            <Route path={PATH.VISITOR_TYPE} element={<AcquisitionForm />} />
            <Route path={PATH.VISITOR_LIST_TYPE} element={<AcquisitionList />} />
            <Route path={PATH.DASHBOARD} element={<Home />} />
            <Route path={PATH.USERS_LIST} element={<UserCreationForm />} />
            <Route path={PATH.USER_TYPE} element={<AddUser />} />
            <Route path={PATH.DEPARTMENT_TYPE} element={<DepartmentAdd />} />
            <Route path={PATH.DEPARTMENT_LIST} element={<DepartmentList />} />
            <Route path={PATH.ROLE_TYPE} element={<RoleAdd />} />
            <Route path={PATH.ROLES_LIST} element={<RoleList />} />
            <Route path={PATH.REPORT_LIST} element={<ReportList />} />
            <Route path={PATH.ACCESS_DENIED} element={<AccessDenied />} />
            <Route path={PATH.EMPLOYEE_LIST} element={<Employee />} />
            <Route path={PATH.EMPLOYEE_ADD} element={<EmployeeAdd />} />
            <Route path={PATH.EMPLOYEE_TYPE} element={<EmployeeAdd />} />
            <Route path={PATH.UPLOADE_EMPLOYEE} element={<UploadEmployee />} />
          </Route>
        </Routes>
      </Suspense>

    </div>
  );
}

export default App;
