import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const ReportApi = createApi({
    reducerPath: 'ReportApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }
    }),
    tagTypes: ['report'],
    endpoints: (builder) => ({
        getReportList: builder.query<any, any>({
            query: (searchPrams: any) => ({
                url: urlConstants.REPORT,
                method: 'GET',
                params: {
                    fromDate: searchPrams.startDate,
                    toDate: searchPrams.endDate,
                    restricted: searchPrams.restricted,
                    purpose: searchPrams.responseType,
                    page: searchPrams.page,
                    // limit: 2
                }
            }),
            providesTags: ['report'],
            keepUnusedDataFor: 0
        })
    })

})
export const {
    useGetReportListQuery
} = ReportApi


