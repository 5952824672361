import { configureStore, combineReducers } from '@reduxjs/toolkit'
import errorMessageReducer from '../reducer/errorMessageReducer';
import { loginApi } from '../../services/apiService/login/login';
import alertReducer from '../reducer/alertReducer';
import { AquisitionApi } from '../../services/apiService/acquisitionForm/acquisitionForm';
import { UsersApi } from '../../services/apiService/users/users';
import { RoleApi } from '../../services/apiService/roles/roles';
import { DepartmentApi } from '../../services/apiService/department/department';
import { PermissionApi } from '../../services/apiService/permissions/rolePermissions';
import { profileApi } from '../../services/apiService/profile/profile';
import { DashboardApi } from '../../services/apiService/dashboard/dashboard';
import { ReportApi } from '../../services/apiService/report/report';
import { EmployeeApi } from '../../services/apiService/employee/employee';
import { uploadEmployee } from'../../services/apiService/uploadEmployee/uploadEmployee';
const rootReducers = combineReducers({
    ErrorMessageReducer: errorMessageReducer,
    alertReducer: alertReducer,
    loginApi: loginApi.reducer,
    AquisitionApi: AquisitionApi.reducer,
    UsersApi: UsersApi.reducer,
    RoleApi: RoleApi.reducer,
    DepartmentApi: DepartmentApi.reducer,
    PermissionApi: PermissionApi.reducer,
    profileApi: profileApi.reducer,
    DashboardApi: DashboardApi.reducer,
    ReportApi: ReportApi.reducer,
    EmployeeApi: EmployeeApi.reducer,
    uploadEmployee:uploadEmployee.reducer
})

const store = configureStore({
    reducer: rootReducers,
    middleware: (getdefaultMiddleware) => getdefaultMiddleware().concat([
        loginApi.middleware,
        profileApi.middleware,
        AquisitionApi.middleware,
        UsersApi.middleware,
        RoleApi.middleware,
        DepartmentApi.middleware,
        PermissionApi.middleware,
        DashboardApi.middleware,
        ReportApi.middleware,
        EmployeeApi.middleware,
        uploadEmployee.middleware
    ])
})
export default store

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;