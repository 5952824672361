import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialState = {
    showAlert: any;
    messageNotify: boolean;
    messageInfo: string;
    messageType: string;
}

const initialState: initialState = {
    messageNotify: false,
    messageInfo: "",
    messageType: "",
    showAlert: undefined
}
export const showAlertInitial={
    showModel: false,
        type: '',
        message: '',
        deleteAlert: false
}

const AlertMessageSlice = createSlice({
    name: 'AlertMessage',
    initialState,
    reducers: {
        setMessageInfo: (
            state: initialState,
            action: PayloadAction<string>
        ) => {
            state.messageInfo = action.payload;
        },
        setMessageNotify: (
            state: initialState,
            action: PayloadAction<boolean>
        ) => {
            state.messageNotify = action.payload;
        },
        setMessageType: (
            state: initialState,
            action: PayloadAction<string>
        ) => {
            state.messageType = action.payload;
        },
        setShowAlert: (state: initialState, action: PayloadAction<initialState["showAlert"]>) => {
            state.showAlert = action.payload;
        }


    }
})

export default AlertMessageSlice.reducer

export const {
    setMessageNotify,
    setMessageType,
    setMessageInfo,
    setShowAlert,
} = AlertMessageSlice.actions;